import Vue, { VueConstructor } from "vue";

declare module 'vue/types/vue' {
  interface Vue {
    $a8Track: (config: any) => void;
  }
}

declare global {
  interface Window {
    a8sales?: (config: any) => void;
  }
}

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$a8Track = (config: any) => {
      // A8のトラッキングロジック
      if (window.a8sales) {
        window.a8sales(config);
      }
    };
  },
};
