<template>
  <v-footer padless fixed>
    <v-col class="text-center" cols="12">
      入会金: {{ addmissionFee() | toJPY }} <span class="red--text">*</span
      ><span class="inclue-tax"
        >(税込: {{ (addmissionFee() * 1.1) | toJPY }})</span
      >
      <p class="mb-0 red--text text-caption">
        * 体験７日以内のご入会はキャンペーンに基づく金額となります。
      </p>
      お月謝: {{ monthlyFee() | toJPY }}
      <span class="inclue-tax">(税込: {{ (monthlyFee() * 1.1) | toJPY }})</span>
    </v-col>
  </v-footer>
</template>

<script>
import { useApplication } from "@/models/application";

export default {
  setup() {
    const { application, monthlyFee, addmissionFee } = useApplication();
    return { application, monthlyFee, addmissionFee };
  },
  filters: {
    toJPY(value) {
      return `${value.toLocaleString()}円`;
    },
  },
};
</script>
<style scoped lang="scss">
.inclue-tax {
  font-size: 14px;
}
</style>
