<template>
  <div>
    <p class="font-weight-bold">2. コースを選択してください</p>

    <v-container fluid>
      <v-row dense>
        <v-col>
          <v-card
            class="mx-auto ma-2"
            :class="application.value.course == 'basic' ? 'active' : ''"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1 text-center">
                  ベーシックコース
                </v-list-item-title>
                <v-list-item-subtitle class="text-center"
                  >英語初心者～中級者・更に英語を伸ばしたい方向け</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn
                outlined
                rounded
                text
                class="mx-auto"
                @click="selectCourse('basic')"
              >
                選択する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="mx-auto ma-2"
            :class="application.value.course == 'advanst' ? 'active' : ''"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1 text-center">
                  アドバンストコース
                </v-list-item-title>
                <v-list-item-subtitle class="text-center"
                  >帰国子女・インターナショナルスクール在学生・英検2級レベル以上・中学生向け</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn
                outlined
                rounded
                text
                class="mx-auto"
                @click="selectCourse('advanst')"
              >
                選択する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col>
          <v-card
            class="mx-auto ma-2"
            :class="application.value.course == 'international' ? 'active' : ''"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1 text-center">
                  インターナショナルスクール受験コース
                </v-list-item-title>
                <v-list-item-subtitle class="text-center">インターナショナルスクール受験生向け</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn
                outlined
                rounded
                text
                class="mx-auto"
                @click="selectCourse('international')"
              >
                選択する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>    -->
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { useApplication, selectCourse } from "@/models/application";

export default {
  setup() {
    const { application } = useApplication();
    return { application, selectCourse };
  },
};
</script>

<style scoped lang="scss">
.active {
  border-color: #42b983;
  background-color: #c7e0e0;
}
</style>
