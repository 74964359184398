import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueGtm from "@gtm-support/vue2-gtm";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "お申込み(1/5)", desc: "" },
  },
  {
    path: "/forms2",
    name: "Forms2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forms2.vue"),
    meta: { title: "お申込み(2/5)", desc: "" },
  },
  {
    path: "/forms3",
    name: "Forms3",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forms3.vue"),
    meta: { title: "お申込み(3/5)", desc: "" },
  },
  {
    path: "/forms4",
    name: "Forms4",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forms4.vue"),
    meta: { title: "お申込み(4/5)", desc: "" },
  },
  {
    path: "/complete",
    name: "Complete",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Complete.vue"),
    meta: { title: "お申込み完了", desc: "" },
  },
  // Trial
  {
    path: "/trial",
    name: "Trial",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Trial.vue"),
    meta: { title: "無料 Online 体験レッスンへのお申込み", desc: "" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

Sentry.init({
  Vue,
  dsn: "https://ceaca0b01ba5453e8f7039f19c365466@o317286.ingest.sentry.io/6073827",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "selan-omsister-forms-dev.web.app",
        "forms.omsister.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.use(VueGtm, {
  id: "GTM-PRHNTN3", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: "2726c7f26b", // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

export default router;
