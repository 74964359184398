import firebase from 'firebase'

if (!firebase.apps.length) {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser && location.host === 'forms.omsister.com') {
    firebase.initializeApp({
      apiKey: 'AIzaSyByFD18plX4Px54AgitSI9TMqLVjL_8RkU',
      authDomain: 'mypage-prod.firebaseapp.com',
      databaseURL: 'https://mypage-prod.firebaseio.com',
      projectId: 'mypage-prod',
      storageBucket: 'mypage-prod.appspot.com',
      messagingSenderId: '129113667337',
      appId: '1:129113667337:web:e897dbaa7459fa6b2e05d8',
      measurementId: 'G-JVH2JM72VN',
    })
    // firebase.analytics()
  } else {
    firebase.initializeApp({
      apiKey: 'AIzaSyDXv1kP_WGMqtNnlWjqELO7N0yPr3KHsxc',
      authDomain: 'selan-omsister-mypage.firebaseapp.com',
      databaseURL: 'https://selan-omsister-mypage.firebaseio.com',
      projectId: 'selan-omsister-mypage',
      storageBucket: 'selan-omsister-mypage.appspot.com',
      messagingSenderId: '800289779629',
      appId: '1:800289779629:web:b8ff6bb37600926e567b24',
      measurementId: 'G-V96HC48J7P',
    })
    // firebase.analytics()
  }

  const functions = firebase.functions()
  if (process.env.NODE_ENV !== 'production') {
    functions.useFunctionsEmulator('http://localhost:5001')
  }
}

export default firebase