<template>
  <div>
    <lesson-type />
    <course />
    <LessonTime />
    <number-of-lesson />
    <options />
  </div>
</template>

<script>
import LessonType from "@/components/forms/LessonType.vue";
import Course from "@/components/forms/Course.vue";
import LessonTime from "@/components/forms/LessonTime.vue";
import Options from "@/components/forms/Options.vue";
import NumberOfLesson from "./forms/NumberOfLesson.vue";

export default {
  data: () => ({}),
  components: {
    LessonType,
    Course,
    LessonTime,
    Options,
    NumberOfLesson,
  },
};
</script>
