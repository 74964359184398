import {
  ADMISSION_FEES,
  LESSON_TIME_CANDIDATES,
  MONTHLY_FEES,
} from "@/constant";
import firebase from "@/plugins/firebase";
import {
  ref,
  onMounted,
  onUnmounted,
  readonly,
  computed,
} from "@vue/composition-api";
import { app } from "firebase";

export class Application {
  lessonType: string = "offline";
  course: string = "basic";
  lessonTime: Number = 90;
  numberOfLesson: Number = 1;
  transfer: boolean = false;
  eiken: boolean = false;

  name: string = "";
  furi: string = "";
  email: string = "";
  // 電話番号
  tel: string = "";
  // 郵便番号
  zip: string = "";
  // ご自宅住所
  address: string = "";
  // ご自宅住所(online 市区町村)
  address2: string = "";
  // 最寄り駅
  station: string = "";
  // ご希望曜日第1希望
  firstDateRange: any = {
    week: "",
    time: "",
    minute: "",
    timeTo: "",
    minuteTo: "",
  };
  // ご希望曜日第2希望
  secondDateRange: any = {
    week: "",
    time: "",
    minute: "",
    timeTo: "",
    minuteTo: "",
  };
  // ご希望曜日第3希望
  thirdDateRange: any = {
    week: "",
    time: "",
    minute: "",
    timeTo: "",
    minuteTo: "",
  };
  // レッスン希望時間に関する備考
  lessionRequestTimeNote: string = "";
  // レッスン希望時間に関するその他ご要望欄
  notes: string = "";
  // レッスン開始日第1希望
  firstDay: Date | null = null;
  // レッスン開始日第2希望
  secondDay: Date | null = null;

  // お子様のお名前
  childName: string = "";
  // ふりがな'
  childFuri: string = "";
  // お子様の年齢
  childAge: string = "";
  // お子様の生年月日
  childBirth: string = "";
  // お子様の性別
  childSex: string = "";
  // 重要情報アレルギーなど
  childAllergy: string = "";

  // お迎え先
  pickupout: string = "";
  // お迎え先郵便番号
  childZip: string = "";
  // お迎え先住所
  childAddress: string = "";
  // お迎え先名
  childSpot: string = "";
  // // 先生に関するご希望
  // teacher: string = ''
  // 飼われているペットの種類
  pet: string = "";
  // // その他ご要望
  // message: string = ''
  // ご紹介者様
  introducer: string = "";

  // 利用規約に同意する
  agreement: boolean = false;

  // 送信済みかどうか
  isFormSubmitted: boolean = false;
}

const application = ref<Application>(new Application());

const lessonTimes = ref<Number[]>([]);
const updateLessonTimes = () => {
  lessonTimes.value =
    LESSON_TIME_CANDIDATES[application.value.lessonType][
      application.value.course
    ];
  application.value.lessonTime = lessonTimes.value[0];
};
updateLessonTimes();

export const updateLessonType = (lessonType: string) => {
  application.value.lessonType = lessonType;
  updateLessonTimes();
};

export const selectCourse = (course: string) => {
  application.value.course = course;
  updateLessonTimes();
};

export const updateChildBirth = (childBirth: string) => {
  application.value.childBirth = childBirth;
};

const monthlyFee = () => {
  const l = MONTHLY_FEES[application.value.lessonType];
  let c = l[application.value.course];
  if (c === undefined) {
    c = l[Object.keys(l)[0]];
  }
  let val = c[String(application.value.lessonTime)];
  if (val === undefined) {
    val = c[Object.keys(c)[0]];
  }
  return val[Number(application.value.numberOfLesson)];
};

const addmissionFee = () => {
  return ADMISSION_FEES[application.value.lessonType];
};

const numberOfLessonOptions = () => {
  if (
    application.value.lessonType === "online" &&
    application.value.lessonTime !== 60
  ) {
    return [2, 3, 4, 5];
  }

  return [1, 2, 3, 4, 5];
};

const sendApplication = async (token: string) => {
  const func = firebase.functions().httpsCallable("sendApplication");
  await func({
    token,
    ...application.value,
  });
  application.value.isFormSubmitted = true;
};

export const useApplication = () => {
  return {
    application: readonly(application),
    lessonTimes: readonly(lessonTimes),
    monthlyFee,
    addmissionFee,
    numberOfLessonOptions,
    sendApplication,
  };
};
