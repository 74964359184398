<template>
  <div>
    <p class="font-weight-bold">3. レッスン時間を選択ください</p>
    <v-radio-group v-model="application.value.lessonTime" row>
      <v-radio
        v-for="(lt, index) in lessonTimes.value"
        :key="index"
        :label="`${lt}分/1レッスン`"
        :value="lt"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { useApplication } from "@/models/application";

export default {
  setup() {
    const { application, lessonTimes } = useApplication();
    return { application, lessonTimes };
  },
};
</script>
