<template>
  <v-container>
    <h2 class="text-center mb-5">お申し込みフォーム(1/5)</h2>
    <forms />
    <div class="d-block text-center">
      <router-link to="/forms2">
        <v-btn depressed color="primary"> 次へ </v-btn>
      </router-link>
    </div>

    <form-footer />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Forms from "@/components/Forms.vue";
import FormFooter from "@/components/FormFooter.vue";

@Component({
  components: {
    Forms,
    FormFooter,
  },
})
export default class Home extends Vue {}
</script>
