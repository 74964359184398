<template>
  <v-app>
    <form-header />

    <v-main class="main-container">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import FormHeader from "./components/FormHeader.vue";

export default {
  name: "Home",
  components: {
    FormHeader,
  },
  methods: {
    createTitleDesc: function (routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + " | お迎えシスター";
        document.title = setTitle;
      } else {
        document.title = "";
      }

      // メタタグdescription設定
      if (routeInstance.meta.desc) {
        var setDesc = routeInstance.meta.desc + " | お迎えシスター";
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", "");
      }
    },
  },
  mounted() {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
};
</script>

<style lang="scss">
.main-container {
  padding-bottom: 100px !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
