import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./plugins/composition-api";
import App from "@/App.vue";
import router from "./router";

Vue.config.productionTip = false;

import "@/assets/style.scss";

import a8Tracking from "./plugins/a8-tracking";
Vue.use(a8Tracking);

new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount("#app");
