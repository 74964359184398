<template>
  <div>
    <p class="font-weight-bold">4. レッスン回数を選択ください</p>
    <v-radio-group v-model="application.value.numberOfLesson" row>
      <v-radio
        v-for="index in numberOfLessonOptions()"
        :key="index"
        :label="`週${index}回/月${index * 4}回`"
        :value="index"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { useApplication } from "@/models/application";

export default {
  setup() {
    const { application, numberOfLessonOptions } = useApplication();
    return { application, numberOfLessonOptions };
  },
};
</script>
